<template>
  <div style="margin-bottom: 60px">
    <div class="odertops">
      <ul class="lefttop">
        <li
          class="list"
          v-for="(item, index) in tabber"
          :key="index"
          @click="tocard(index)"
          :style="
            active == index
              ? 'border-bottom:2px solid #00A3E0;color:#00A3E0;'
              : ''
          "
        >
          {{ item.name }}
        </li>
      </ul>
      <div class="sherch">
        <input
          type="text"
          class="inputs"
          placeholder="请输入商品名称或订单号"
          v-model="parms.goodsName" @keyup.enter='tocard(100)'
        />
        <div class="btns" @click="tocard(100)">搜索</div>
      </div>
    </div>
    <div class="tabbarcenter">
      <div class="tabtops">
        <div class="benes widthsone">商品</div>
        <div class="benes widthstwo">单价</div>
        <div class="benes widthsther">数量</div>
        <div class="benes widthstfor">实付款</div>
        <div class="benes widthstfor">状态</div>
        <div class="benes widthstfav">操作</div>
      </div>
      <div class="tabberlis" v-for="(ims, idx) in oderList" :key="idx">
        <div class="lsittop">
          <span class="name">售后单号:{{ ims.sn }}</span
          ><span class="dates">{{ ims.createTime }}</span>
        </div>
        <div class="googbottom">
          <div class="leftlsits" ref="alllist">
            <div class="goodslist">
              <div class="goodsones">
                <img class="imgtops" :src="ims.goodsImage" alt="" />
                <div class="goodstext">
                  <div class="tops">
                    {{ ims.goodsName }}
                  </div>
                  <div class="skus">{{ ims.num }}</div>
                </div>
              </div>
              <div class="price" v-if="ims.promotionType == 'POINTS_GOODS'">{{ ims.point || 0 }}积分</div>
              <div class="price" v-else>￥{{ ims.unitPrice || 0 }}</div>
              <div class="nums">{{ ims.num }}</div>
            </div>
          </div>
          <div class="shiji" :style="'height:' + height + 'px;'">
            ￥{{ ims.applyRefundPrice }}
          </div>
          <div class="status" :style="'height:' + height + 'px;'">
            <span v-if="ims.serviceStatus == 'APPLY'">申请中</span>
            <span v-if="ims.serviceStatus == 'PASS'">已通过</span>
            <span v-if="ims.serviceStatus == 'REFUSE'">已拒绝</span>
            <span v-if="ims.serviceStatus == 'BUYER_RETURN'">待卖家收货</span>
            <span v-if="ims.serviceStatus == 'SELLER_CONFIRM'"
              >卖家确认收货</span
            >
            <!-- <span v-if="ims.serviceStatus == 'SELLER_TERMINATION'"
              >已拒绝</span
            > -->
            <span v-if="ims.serviceStatus == 'BUYER_CANCEL'">买家取消售后</span>
            <span v-if="ims.serviceStatus == 'WAIT_REFUND'">等待平台退款</span>
            <span v-if="ims.serviceStatus == 'COMPLETE'">完成</span>
          </div>
          <div class="caozuo">
            <div class="btns" @click="todetails(ims)">售后详情</div>
            <div class="btns" v-if="ims.serviceStatus == 'PASS'" @click="tocartlist(ims)">寄回商品</div>
            <div
              class="btns"
               v-if="ims.serviceStatus == 'APPLY' || ims.serviceStatus == 'PASS'"
              @click="postafterSaleSnfterSaleSnApi(ims)"
            >
              取消申请
            </div>
          </div>
          <div
            style="font: 0px/0px sans-serif; clear: both; display: block"
          ></div>
        </div>
      </div>
      <div
        style="
          width: 100%;
          text-align: right;
          margin-top: 40px;
          margin-bottom: 30px;
        "
      >
        <el-pagination
          background
          layout="prev, pager, next"
          :total="alltotal"
          @current-change="currentchange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="填写物流"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <div class="zhifu">
        <el-form label-width="80px" style="width: 100%">
          <el-form-item label="物流公司">
            <el-select
              v-model="logisticsId"
              placeholder="请选择"
              style="width: 100%"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物流单号">
            <el-input v-model="logisticsNo" @input="inpustlsit"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ookzhifus">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getafterSalepage,
  postafterSaleSnfterSaleSn,
  postdeliveryafterSaleSn,
  getalogisticsList,
} from "../../request/homeApi";
export default {
  data() {
    return {
      options: [],
      logisticsId: "",
      sn: "",
      radio: "",
      centerDialogVisible: false,
      logisticsNo: "",
      tabber: [
        {
          name: "全部",
          value: "",
        },
        {
          name: "申请中",
          value: "APPLY",
        },
        {
          name: "已通过",
          value: "PASS",
        },
        {
          name: "已拒绝",
          value: "REFUSE",
        },
        {
          name: "已退货，待卖家收货",
          value: "BUYER_RETURN",
        },
        {
          name: "完成售后",
          value: "COMPLETE",
        },
        {
          name: "取消售后",
          value: "BUYER_CANCEL",
        },
      ],
      active: 0,
      oderList: [],
      height: 0,
      alltotal: 0,
      parms: {
        pageNumber: 1,
        pageSize: 10,
        serviceStatus: "",
        goodsName: "",
      },
      reason: "",
    };
  },
  mounted() {
    // this.height = this.$refs.alllist.clientHeight;
    this.getafterSalepageApi();
    this.getalogisticsListApi();
  },
  methods: {
    inpustlsit(e){
      var reg = /^[A-Za-z0-9]+$/;
			if (!reg.test(e)) {
				this.logisticsNo = '';
				return false;
			}
    },
    // 获取物流公司
    getalogisticsListApi() {
      getalogisticsList()
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            res.result.forEach((element) => {
              element.label = element.name;
              element.value = element.id;
            });
            this.options = res.result;
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
    ookzhifus() {
      let strings = {
        afterSaleSn: this.sn,
        logisticsId: this.logisticsId,
        logisticsNo: this.logisticsNo,
      };
      postdeliveryafterSaleSn(strings)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.centerDialogVisible = false;
            this.getafterSalepageApi();
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    }, // 立即支付
    tocartlist(item) {
      this.sn = item.sn;
      this.centerDialogVisible = true;
    },
    // 取消订单
    postafterSaleSnfterSaleSnApi(item) {
      this.$confirm("此操作将取消订单 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          postafterSaleSnfterSaleSn(item.sn)
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "取消订单成功!",
                });
                // this.parms.pageNumber = 1;
                this.getafterSalepageApi();
              }
            })
            .catch((res) => {
              console.log("请求失败");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 点击分页
    currentchange(index) {
      this.parms.pageNumber = index;
      this.getafterSalepageApi();
    },
    // 订单列表
    getafterSalepageApi() {
      getafterSalepage(this.parms)
        .then((res) => {
          if (res.code == 200) {
            this.alltotal = res.result.total;
            this.oderList = res.result.records;
          }
        })
        .catch((res) => {
          console.log("请求失败");
        });
    },
    todetails(item) {
     this.$router.push({
        path: "/mepages/aftersale?orderSn=" + item.sn + "&ids=" + item.goodsId + "&active=100",
      });
    },
    tocard(index) {
      this.parms.pageNumber = 1;
      if (index != 100) {
        this.parms.serviceStatus = this.tabber[index].value;
        this.active = index;
      }

      this.getafterSalepageApi();
    },
  },
};
</script>

<style lang="scss" scoped>
.odertops {
  display: flex;
  align-items: center;
  height: 63px;
  padding: 0 20px;
  justify-content: space-between;
  background: #ffffff;
  .lefttop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    cursor: pointer;
    .list {
      height: 100%;
      padding: 0 15px;
      line-height: 63px;
      font-size: 14px;
      color: #535353;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .sherch {
    display: flex;
    align-items: center;
    width: 300px;
    height: 36px;
    background: #ffffff;

    .inputs {
      width: 220px;
      height: 36px;
      padding: 10px 15px;
      line-height: 36px;
      font-size: 14px;
      color: #999999;
      border: 1px solid #e5e5e5e5;
    }
    .btns {
      width: 80px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      background: #00a3e0;
      border: 1px solid #00a3e0;
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
.tabbarcenter {
  margin-top: 10px;
  background: #ffffff;
  padding: 15px 17px;
  .tabtops {
    width: 100%;
    height: 36px;
    background: #f3f3f3;
    display: flex;
    align-items: center;
    padding-left: 15px;
    .benes {
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      text-align: center;
      color: #535353;
    }
    .widthsone {
      width: 400px;
      text-align: left;
      padding-left: 80px;
    }
    .widthstwo {
      width: 100px;
    }
    .widthsther {
      width: 100px;
    }
    .widthstfor {
      width: 120px;
    }
    .widthstfav {
      width: 120px;
    }
  }
  .tabberlis {
    margin-top: 10px;
    border: 1px solid #f0f0f0;
    .lsittop {
      width: 100%;
      height: 36px;
      background: #f3f3f3;
      padding: 0 15px;
      line-height: 36px;
      font-size: 12px;
      color: #535353;
      .dates {
        padding-left: 22px;
        font-size: 12px;
        color: #818181;
      }
    }
    .googbottom {
      display: flex;
      align-items: center;

      height: 100%;
      .leftlsits {
        height: 100%;
        .goodslist {
          padding-left: 15px;
          width: 620px;
          height: 118px;
          border-top: 1px solid #f0f0f0;
          display: flex;
          align-items: center;
          .goodsones {
            display: flex;
            align-items: center;
            .imgtops {
              width: 135px;
              height: 72px;
              margin-right: 10px;
            }
            .goodstext {
              .tops {
                width: 256px;
                font-size: 14px;
                color: #535353;
              }
              .skus {
                margin-top: 10px;
                font-size: 14px;
                color: #818181;
              }
            }
          }
          .price {
            width: 140px;
            height: 118px;
            line-height: 118px;
            text-align: center;
            font-size: 14px;
            color: #535353;
          }
          .nums {
            width: 140px;
            height: 118px;
            line-height: 118px;
            text-align: center;
            font-size: 14px;
            color: #535353;
            border-right: 1px solid #f0f0f0;
          }
        }
      }

      .shiji {
        width: 115px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #535353;
        border-right: 1px solid #f0f0f0;
      }
      .status {
        width: 115px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #535353;
        border-right: 1px solid #f0f0f0;
      }
      .caozuo {
        width: 115px;
        color: #00a3e0;
        font-size: 14px;
        .btns {
          width: 100%;
          text-align: center;
          margin-bottom: 14px;
          cursor: pointer;
        }
      }
    }
  }
}
.zhifu {
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  .wecart {
    display: flex;
    align-items: center;
    margin-right: 33px;
    .cartimg {
      width: 38px;
      height: 34px;
      margin-right: 11px;
    }
  }
  .alply {
    display: flex;
    align-items: center;
    .alplyimg {
      width: 34px;
      height: 34px;
      margin-right: 11px;
    }
  }
}
</style>